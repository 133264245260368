import React from "react";
import { Link } from "react-router-dom";
import homescreen from '../assets/homescreen.jpg';

function Work() {
  return (
    <section>
    <div className="title">
      <h1>Come Join the Team</h1>
      <p>No Vacancies at the moment! Please check back later!</p>
      <p>Employers: Please <Link to="/contact">contact us</Link> if you have any vacancies.</p>
    </div>
  </section>
  );
}

export default Work;
