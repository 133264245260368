import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import facebook from "../assets/facebook.png";
import instagram from "../assets/instagram.png";
import twitter from "../assets/twitter.png";
import email from "../assets/email.png";
import TypeWriterEffect from "react-typewriter-effect";

function Header() {
  const [isToggled, setToggled] = useState(false);

  return (
    <header>
      <div className="logo">
        <TypeWriterEffect
          // textStyle={{
          //   fontWeight: 600,
          //   fontSize: "1.5em",
          // }}
          startDelay={100}
          cursorColor="red"
          text="Celotrac "
          typeSpeed={100}
          hideCursorAfterText={true}
        />
      </div>
      <div
        className={!isToggled ? "toggle" : "toggle active"}
        onClick={() => {
          setToggled(!isToggled);
        }}
      ></div>
      <div className={!isToggled ? "navigation" : "navigation active"}>
        <ul
          onClick={() => {
            setToggled(!isToggled);
          }}
        >
          <li>
            <NavLink to="/">Home</NavLink>
          </li>
          <li>
            <NavLink to="/services">Services</NavLink>
          </li>
          <li>
            <NavLink to="/careers">Careers</NavLink>
          </li>
          <li>
            <NavLink to="/contact">Contact</NavLink>
          </li>
        </ul>
        <div className="social-bar">
          <ul>
            <li>
              <a>
                <img src={facebook} alt="" />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/celotracltd">
                <img src={twitter} alt="" />
              </a>
            </li>
            <li>
              <a>
                <img src={instagram} alt="" />
              </a>
            </li>
          </ul>
          <a href="mailto:info@celotrac.com" className="email-icon">
            <img src={email} alt="" />
          </a>
        </div>
      </div>
    </header>
  );
}

export default Header;
