import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import address from "../assets/address.png";
import email from "../assets/email.png";
import call from "../assets/call.png";
import TypeWriterEffect from 'react-typewriter-effect';

function Contact() {
  const form:any = useRef();

  const [feedback, setFeedback] = useState('')

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_a85ndfe', 'template_8tjzzwu', form.current, 'GZRfopANI8YTU9hqx')
      .then((result) => {
          form.current.reset();
          setFeedback("Thank you for contacting us..")
      }, (error) => {
          console.log(error.text);
      });

  };

  return (
    <section>
      <div className="title">
        <h1>
          <TypeWriterEffect
            startDelay={100}
            cursorColor="red"
            text="Hello. Howdy. Habari"
            typeSpeed={100}
            hideCursorAfterText={false}
          />
        </h1>
        <p>
          Could you be our newest friend? We certainly hope so. Don't hesitate
          to say hi and meet our team.
        </p>
      </div>
      <div className="contact">
        <div className="contact-form">
          { feedback && <div>    
            <TypeWriterEffect
            textStyle={{
              fontSize: '1.1rem',
            }}
            startDelay={100}
            cursorColor="red"
            text={feedback}
            loop={true}
            typeSpeed={20}
            hideCursorAfterText={false}
          /></div>}
          <form ref={form} onSubmit={sendEmail}>
            <div className="row">
              <div className="input50">
                <input type="text" name="name" placeholder="Name" required />
              </div>
            </div>
            <div className="row">
              <div className="input100">
                <input type="email" name="email" placeholder="Email" required />
              </div>
            </div>
            <div className="row">
              <div className="input100">
                <textarea placeholder="Message" name="message" required></textarea>
              </div>
            </div>
            <div className="row">
              <div className="input100">
                <input type="submit" value="Send" />
              </div>
            </div>
          </form>
        </div>

        <div className="contact-info">
          <div className="info-box">
            <img src={address} className="contact-icon" alt="" />
            <div className="details">
              <h4>Address</h4>
              <span>Suite 16, Sina complex Jinja Rd, Mukono Uganda</span>
            </div>
          </div>
          <div className="info-box">
            <img src={email} className="contact-icon" alt="" />
            <div className="details">
              <h4>Email</h4>
              <span>info@celotrac.com</span>
              <br />
              <span>hello@celotrac.com</span>
            </div>
          </div>
          <div className="info-box">
            <img src={call} className="contact-icon" alt="" />
            <div className="details">
              <h4>Call</h4>
              <span>
                <TypeWriterEffect
                  startDelay={100}
                  cursorColor="red"
                  text="....."
                  typeSpeed={100}
                  hideCursorAfterText={true}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
