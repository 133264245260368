import React from "react";

function Blog() {
  return (
      <section className="blog">
       
      </section>
  );
}

export default Blog;
