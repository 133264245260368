import React from "react";
import { Link } from "react-router-dom";
import homescreen from "../assets/homescreen.jpg";
import TypeWriterEffect from "react-typewriter-effect";

function Home() {
  return (
    <section className="home">
      <img src={homescreen} className="home-img" alt="" />
      <div className="home-content">
        <h1>
        <TypeWriterEffect
            startDelay={500}
            cursorColor="red"
            text="Turning innovation into reality "
            typeSpeed={100}
            hideCursorAfterText={false}
          />
        </h1>
        <p>
          Let us step into the future of your brand through craft and curiosity.
        </p>
        <Link to="/contact" className="btn">
          Get Started
        </Link>
      </div>
    </section>
  );
}

export default Home;
