import React from "react";
import one from "../assets/001.png";
import two from "../assets/002.png";
import three from "../assets/003.png";
import four from "../assets/004.png";
import five from "../assets/005.png";
import six from "../assets/006.png";
import TypeWriterEffect from 'react-typewriter-effect';

function Services() {
  return (
    <section>
      <div className="title">
        <h1>
        <TypeWriterEffect
            startDelay={100}
            cursorColor="red"
            text="The technology partner you’ve been waiting for "
            typeSpeed={100}
            hideCursorAfterText={false}
          /></h1>
        <p>
          Every day, we tread new ground with our clients to ensure fans across
          the globe are given experiences that go way beyond their expectation –
          and leave them coming back time and time again. Our many successes are
          achievable because we are a diverse team connected by one ambition –
          to change the way the world consumes sport and entertainment
        </p>
      </div>
      <div className="services">
        <div className="service">
          <div className="icon">
            <img src={one} alt="" />
          </div>
          <h2>Design</h2>
         
        </div>
        <div className="service">
          <div className="icon">
            <img src={two} alt="" />
          </div>
          <h2>Development</h2>
          
        </div>
        <div className="service">
          <div className="icon">
            <img src={three} alt="" />
          </div>
          <h2>SEO</h2>
         
        </div>
        <div className="service">
          <div className="icon">
            <img src={four} alt="" />
          </div>
          <h2>Marketing</h2>
         
        </div>
        <div className="service">
          <div className="icon">
            <img src={five} alt="" />
          </div>
          <h2>App Development</h2>
          
        </div>
        <div className="service">
          <div className="icon">
            <img src={six} alt="" />
          </div>
          <h2>Error Fixing</h2>
         
        </div>
      </div>
    </section>
  );
}

export default Services;
